.sweetalert-button {
    background-color: #162b4e; /* Cambia esto al color deseado */
    color: #fff; /* Cambia esto al color deseado para el texto del botón */
    border: 1px solid #162b4e; /* Cambia esto al color deseado para el borde del botón */
    padding: 15px; /* Ajusta el relleno según sea necesario */
    border-radius: 1rem;
    transition: 0.3s ease;
    font-weight: 700;
    font-size: 17px;
    cursor:pointer;
  }
  
  /* Estilo adicional para el botón de confirmación cuando se presiona (opcional) */
  .sweetalert-button:hover {
    background-color: #123a67; /* Cambia esto al color deseado al pasar el mouse sobre el botón */
    border: 1px solid #123a67; /* Cambia esto al color deseado para el borde del botón al pasar el mouse sobre él */
  }






  