.card:hover {
    transition: all 0.7s linear;
  }

  .card-text {
    padding: 0.3rem 1rem;
    font-size: 1rem;
  }
  
  .overflow {
    overflow: hidden;
    height: 250px;
  }
  
  .card-img-top {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    transition: all 0.2s ease-in-out;
  }
  
  .card-img-top:hover {
    transform: scale(1.8);
  }


  .custom-button-style {
    width: 100%;
    font-weight: 700;
    border: none;
    padding: 10px;
    font-size: 17px;
    background-color: rgb(22, 43, 78);
    color: #fff; 
    border-radius: 5px;
  }
  
  .custom-button-style:hover {
    background-color: #1a2e4a; 
    
  }

  .container-card {
    margin-bottom: 20px;
    
  }

  .card-title {
    padding: 20px;
    height: 90px;
    color:#2D2D45;
  }

  .card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none !important;
    max-height: 500px !important;
    height: 100% !important;
  }

  .ul-info {
    margin-bottom: 20px;
    padding: 0px;
    height: 85px;
  }

